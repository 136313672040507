<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="tasks__row--action text-xs-left" style="padding: 0 10px !important;" @click="taskDialog = true">
      <strong v-if="props.item.customer && props.item.customer.name">
        <span v-text="props.item.customer.first_name"></span><br>
        <span style="color: gray;">({{props.item.customer.name}})</span>
      </strong>
      <span v-else>Não definido</span>
    </td>
    <td class="tasks__row--action text-xs-center" style="padding: 0px !important" @click="taskDialog = true">
      <span v-if="props.item.time_formated">
        <strong style="font-size: 20px;"> {{ props.item.time_formated }}</strong>
      </span>
      <br>  
      <span>
        <strong style="font-size: 12px; color: grey;"> {{ props.item.date_formated }}</strong>
      </span>
    </td>
    <td class="tasks__row--action text-xs-left" style="padding: 0px !important" @click="taskDialog = true">
      <div style="text-align: center;">
        <v-icon :color="props.item.type_info.color">{{props.item.type_info.icon}}</v-icon><br>
        <strong :style="{color: props.item.type_info.color}">{{props.item.type_info.title}}</strong>
      </div>
    </td>
    <td class="tasks__row--action" style="text-align: left;" @click="taskDialog = true">
      <div style="text-align: center;">
        <v-icon :color="props.item.status_info.color">{{props.item.status_info.icon}}</v-icon><br>
        <strong :style="{color: props.item.status_info.color}">{{props.item.status_info.title}}</strong>
      </div>
    </td>
    <td class="tasks__row--action" style="text-align: left;" @click="taskDialog = true">
      <span>{{props.item.description || ' - - - '}}</span>
    </td>
    <td style="text-align: left;">
      <v-btn :disabled="!$Auth.checkPermission('015b8d97')" color="primary" small outline link :to="{ name: 'medical-record', params: { id: props.item.person_id } }">
        <v-icon>assignment</v-icon>
        <span>Prontuário</span>
      </v-btn>
    </td>
    <TaskDialog v-if="taskDialog" :task="props.item" :refresh="config.refresh" :active="taskDialog" v-on:active="closeTaskDialog"></TaskDialog>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  import TaskDialog from '@/default/pages/Calendar/TaskDialog'
  export default {
    name: 'Tasks',
    data: () => {
      return {
        taskDialog: false
      }
    },
    methods: {
      closeTaskDialog: function () {
        this.taskDialog = false
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions,
      TaskDialog
    }
  }
</script>
<style scoped>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .tasks__date {
    border-style: solid;
    border-width: 1px;
    border-color: gray;
    margin: 0;
    text-align: center;
  }
  .tasks__list-tile >>> .v-list__tile {
    height: 20px !important;
    padding: 0 5px;
  }
  .tasks__row--action {
    cursor: pointer;
  }
</style>